@import "./styles/nav-bar.css";
@import "./styles/gradient-effect.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background-color: #1a1a1a;
  --button: #8421ee;
  --primary: #b715cb;
  --white: #ffffff;
}

* {
  padding: 0;
  margin: 0;
}

body {
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--button);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
