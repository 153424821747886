.gradient-background-placeholder {
  height: 50%;
  background: linear-gradient(
    180deg,
    var(--primary),
    var(--button),
    var(--background-color),
    var(--background-color)
  );
  background-size: 200% 200%;
  animation: gradientDefault 5s ease infinite;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 100;
  position: absolute;
}

@keyframes gradientDefault {
  0% {
    background-position: 50% 70%;
  }
  50% {
    background-position: 70% 50%;
  }
  100% {
    background-position: 50% 70%;
  }
}

.gradient-background-placeholder-bottom {
  min-height: 30vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
  background: linear-gradient(
    0deg,
    var(--primary),
    var(--button),
    var(--background-color),
    var(--background-color)
  );
  background-size: 200% 200%;
  animation: gradient 5s ease infinite;
}

@keyframes gradient {
  0% {
    background-position: 30% 50%;
  }
  50% {
    background-position: 50% 30%;
  }
  100% {
    background-position: 30% 50%;
  }
}
